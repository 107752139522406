import Vue from 'vue'
import Axios from 'axios'
import { mapState, mapActions } from 'vuex'
import { Swipe, SwipeItem, Toast, Dialog } from 'vant'

Vue.use(Dialog)
Vue.use(Swipe)
Vue.use(SwipeItem)

export default {

  data () {
    return {
      payPrice: '',
      equityCardCommodityPrice: '',
      equityCardCommodityName: '',
      equityCardCommodityDesc: '',
      equityCardCommodityOnhand: '',
      equityCardCommodityId:'',
      proctImgList: [],
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
      ],
      current: 0,
    }
  },

  // 生命周期 - 创建完成（访问当前this实例）
  created () {
    if (!this.$route.query.pid) {
      var id = localStorage.getItem('sid')
    } else {
      var id = this.$route.query.pid
    }
    this.greenDetail({ id: id }).then(res => {
      if (res.code == 200) {
        console.log(res)
        this.equityCardCommodityPrice = res.data.equityCardCommodityPrice
        this.equityCardCommodityName = res.data.equityCardCommodityName
        this.equityCardCommodityDesc = res.data.equityCardCommodityDesc
        this.equityCardCommodityOnhand = res.data.num
        this.equityCardCommodityId=res.data.equityCardCommodityId
        this.images = res.data.equityCardCommodityImage.split(',')
        this.proctImgList = res.data.equityCardCommodityDetailImage.split(',')
        // var two = res.data.equityCardCommodityPrice.toString().split(".")
        // if (two.length == 1) {
        //   this.payPrice = res.data.equityCardCommodityPrice.toString() + ".00"
        // }

      } else {
        Toast(res.msg)
      }

    })

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    ...mapActions('green', ['greenDetail','webpay']),
    back () {

      this.$router.go(-1)

    },
    onChange (index) {
      this.current = index
    },
    tobuy () {
      var that=this
      var id=this.equityCardCommodityId
      if(parseInt(this.equityCardCommodityOnhand)>0){
        this.webpay({equityCardCommodityId:id}).then(res=>{
          if (res.code == 200) {
            var thedata = JSON.parse(res.data.param);

            //微信支付
            WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
              "appId": thedata.appId,     //公众号名称，由商户传入
              "timeStamp": thedata.timeStamp,         //时间戳，自1970年以来的秒数
              "nonceStr": thedata.nonceStr, //随机串
              "package": thedata.package,
              "signType": thedata.signType,         //微信签名方式：
              "paySign": thedata.paySign //微信签名
            },
              function (res) {

                if (res.err_msg == "get_brand_wcpay_request:ok") {

                  that.$router.push({ name: 'equityCenter' })
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                } else {
                  Toast({
                    message: '支付失败', onClose: function () {
                      // that.$router.push({ name: 'myOrder' })
                    }
                  })

                }
              })
          } else {
            Toast({
              message: res.msg, onClose: function () {
                // that.$router.push({ name: 'myOrder' })
              }
            })

          }
        })


      }else{
        Toast('暂无库存')
      }

    },
    toTop () {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }

  },
}
